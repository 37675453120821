import React, { useState, useEffect, useContext } from "react";
import { getDatabase, ref, get } from "firebase/database";
import { auth } from "../services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { YearContext } from "../contexts/YearContext";
import { useNavigate } from "react-router-dom";

const ProjectedTable = () => {
  const [user] = useAuthState(auth);
  const { seasonYear, nextYear } = useContext(YearContext);
  const [userTable, setUserTable] = useState([]);
  const [projectedScore, setProjectedScore] = useState(0); // State to store the total projected points
  const [showModal, setShowModal] = useState(true); // State to control modal visibility
  const [screenWidth, setScreenWidth] = useState(window.innerWidth); // Track screen width
  const navigate = useNavigate();

  // Handle screen resize
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!user) {
      navigate("/login"); // Redirect to login if user is not authenticated
      return;
    }

    const db = getDatabase();
    const userTableRef = ref(db, `predictions/${seasonYear}-${nextYear}/${user.displayName}`);

    get(userTableRef).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const teamsData = Object.values(data.table).sort(
          (a, b) => a.predictedPosition - b.predictedPosition
        );
        setUserTable(teamsData);
        setProjectedScore(data.projectedScore || 0); // Set the projected score
      }
    });
  }, [user, seasonYear, nextYear, navigate]);

  // Function to get the row styling based on projected points
  const getProjectedClass = (projectedPoints) => {
    switch (projectedPoints) {
      case 5:
        return "bg-green-500 text-white";
      case 3:
        return "bg-blue-500 text-white";
      case 1:
        return "bg-gray-500 text-white";
      case 8:
        return "bg-red-500 text-white";
      case 10:
        return "bg-yellow-500 text-white";
      case 4:
        return "bg-pink-500 text-white";
      case 6:
        return "bg-purple-500 text-white";
      default:
        return "";
    }
  };

  return (
    <>
      {user ? (
        <div className="container mx-auto p-4">
          {/* Modal for Information */}
          {showModal && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded shadow-lg max-w-lg w-full">
                <h2 className="text-2xl font-bold mb-4 text-black">
                  Projected Score Information
                </h2>
                <p className="text-black">
                  The projected score is derived from global power rankings provided by{" "}
                  <a
                    href="https://www.theanalyst.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline text-blue-500"
                  >
                    The Analyst
                  </a>{" "}
                  and Opta Analyst. These rankings are updated Monday through Friday and represent a
                  forecast based on the latest performance data.
                </p>
                <div className="mt-4 text-right">
                  <button
                    className="btn btn-primary"
                    onClick={() => setShowModal(false)}
                  >
                    Got it!
                  </button>
                </div>
              </div>
            </div>
          )}

          <h2 className="text-2xl font-semibold text-center mb-4">
            Your Projected Score | {projectedScore}
          </h2>
          <div className="overflow-x-auto">
            <table className="table w-full bg-base-100 shadow-lg rounded-lg">
              <thead className="bg-neutral text-neutral-content">
                <tr>
                  <th className="py-2 px-4 text-left">{screenWidth < 768 ? "Place" : "Placement"}</th>
                  <th></th>
                  <th className="py-2 px-4 text-left">Team</th>
                  <th className="py-2 px-4 text-left">
                    {screenWidth < 768 ? "Current" : "Current Score"}
                  </th>
                  <th className="py-2 px-4 text-left">
                    {screenWidth < 768 ? "Proj." : "Projected Score"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {userTable.map((team) => (
                  <tr
                    key={team.id}
                    className={`hover:bg-neutral-focus ${getProjectedClass(team.projectedPoints)}`}
                  >
                    <td className="py-2 px-4">{team.predictedPosition}</td>
                    <td className="py-2 px-4">
                      <img
                        src={team.logo}
                        alt={team.teamName}
                        className="w-8 h-8 sm:w-6 sm:h-6 md:w-8 md:h-8 rounded-full"
                      />
                    </td>
                    <td className="py-2 px-4">
                      {screenWidth < 768 ? team.abbr : team.teamName} {/* Conditionally render abbr or teamName */}
                    </td>
                    <td className="py-2 px-4">{team.placementPoints}</td>
                    <td className="py-2 px-4">{team.projectedPoints}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="text-center py-8">Please sign in to view your projected table.</div>
      )}
    </>
  );
};

export default ProjectedTable;
