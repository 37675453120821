import React, { useEffect, useState } from "react";
import { getDatabase, ref, get } from "firebase/database";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../services/firebase";

const TeamSelectionModal = ({ user, isOpen, onClose, onSelectTeam }) => {
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    const fetchTeams = async () => {
      const db = getDatabase();
      const standingsRef = ref(db, `standings/2024/pl`);
      const snapshot = await get(standingsRef);

      if (snapshot.exists()) {
        const teamData = Object.values(snapshot.val());
        const sortedTeams = teamData.sort((a, b) =>
          a.teamName.localeCompare(b.teamName)
        );
        setTeams(sortedTeams);
      }
    };

    fetchTeams();
  }, []);

  const handleTeamSelect = async (teamName, teamLogo) => {
    if (user && teamName && teamLogo) {
      // Check if both values are defined
      const userDocRef = doc(firestore, "users", user.uid);
      await updateDoc(userDocRef, {
        favoriteTeam: teamName,
        favoriteTeamLogo: teamLogo,
      });
      onSelectTeam(teamName, teamLogo);
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded shadow-lg max-w-lg w-full h-3/4 overflow-y-auto">
        <h2 className="text-2xl font-bold mb-4 text-black">
          Select Your Favorite Team
        </h2>
        <div className="grid grid-cols-3 gap-4 text-black">
          {teams.map((team) => (
            <div
              key={team.teamName}
              className="flex flex-col items-center cursor-pointer hover:bg-gray-200 p-2 rounded"
              onClick={() => handleTeamSelect(team.teamName, team.logo)}
            >
              <img
                src={team.logo}
                alt={team.teamName}
                className="w-16 h-16 mb-2"
              />
              <p className="text-center">{team.teamName}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamSelectionModal;
