import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Login from "./components/Login";
import HomePage from "./pages/HomePage";
import TableComps from "./pages/TableComps";
import PredictionsTable from "./pages/PredictionsTable";
import ProjectedTable from "./pages/ProjectedTable";
import Scoring from "./pages/Scoring";
import PreviousStandings from "./pages/PreviousStandings";
import WeeklyProgressChart from "./components/WeeklyProgressChart";
import { YearProvider } from "./contexts/YearProvider";
import PrivateRoute from "./components/PrivateRoute";
import ProtectedRoute from "./components/ProtectedRoute"; // Import the ProtectedRoute component
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./services/firebase";
import Profile from "./pages/Profile";

function App() {
  const [user] = useAuthState(auth);

  return (
    <div className="App">
      <YearProvider>
        <Router>
          {user && <Navbar />} {/* Render Navbar only if user is logged in */}
          <Routes>
            <Route path="/" element={<HomePage />} /> {/* Default route */}
            <Route path="/login" element={<Login />} />
            <Route element={<PrivateRoute />}>
              <Route path="/compare" element={<TableComps />} />
              <Route path="/profile" element={<Profile />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/predictions" element={<PredictionsTable />} />
              </Route>
              <Route path="/projected" element={<ProjectedTable />} />
              <Route path="/scoring" element={<Scoring />} />
              <Route path="/progress" element={<WeeklyProgressChart />} />
              <Route
                path="/previous-standings"
                element={<PreviousStandings />}
              />
            </Route>
          </Routes>
        </Router>
      </YearProvider>
    </div>
  );
}

export default App;
