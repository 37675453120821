//firebase.js
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  sendEmailVerification,
  updateProfile,
  updateEmail,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  deleteUser,
} from "firebase/auth";
import { getDatabase, ref, get, set, update, onValue } from "firebase/database"; // Corrected import
import {
  getFirestore,
  doc,
  collection,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getDatabase(app); // Realtime Database
const firestore = getFirestore(app); // Firestore Database

// Function to check if a Firestore user document exists and create it if not
const checkAndCreateUserDoc = async (user) => {
  const userDocRef = doc(firestore, "users", user.uid);
  const userDocSnapshot = await getDoc(userDocRef);

  if (!userDocSnapshot.exists()) {
    console.log("Firestore user document not found, creating...");
    const initialData = {
      theme: "light", // Default theme
      favoriteTeam: null, // Placeholder for future functionality
      username: user.displayName,
      titles: [], // Placeholder for future functionality
    };
    await setDoc(userDocRef, initialData);
    console.log("Firestore user document created successfully!");
  } else {
    console.log("Firestore user document already exists.");
  }
};

export const fetchUserTitles = async (user) => {
  const userDocRef = doc(firestore, "users", user.uid);
  const userDocSnapshot = await getDoc(userDocRef);

  if (userDocSnapshot.exists()) {
    return userDocSnapshot.data().titles || [];
  }

  return [];
};

// Function to check if a Realtime Database user table exists and create it if not
const checkAndCreateUserTable = async (user, seasonYear, nextYear) => {
  const userStandingsRef = ref(
    db,
    `predictions/${seasonYear}-${nextYear}/${user.displayName}/table`
  );
  const userStandingsSnapshot = await get(userStandingsRef);
  if (!userStandingsSnapshot.exists()) {
    console.log("User table not found, creating...");
    const standingsRef = ref(db, `standings/${seasonYear}/pl`);
    const standingsSnapshot = await get(standingsRef);
    if (standingsSnapshot.exists()) {
      const standings = standingsSnapshot.val();
      const userStandings = Object.keys(standings).map((teamId) => {
        const { rank, ...rest } = standings[teamId];
        return {
          ...rest,
          predictedPosition: rank,
          placementPoints: 0,
          locked: false,
        };
      });
      await set(userStandingsRef, userStandings);

      const userPredictionsRef = ref(
        db,
        `predictions/${seasonYear}-${nextYear}/${user.displayName}`
      );
      await update(userPredictionsRef, {
        totalScore: 0,
      });
      console.log("User table created successfully!");
    }
  } else {
    console.log("User table already exists.");
  }
};

// Register a new user
const registerWithEmailAndPassword = async (
  name,
  email,
  password,
  seasonYear,
  nextYear
) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await updateProfile(user, { displayName: name });
    await sendEmailVerification(user);
    alert(
      "Registration successful! Please check your email to verify your account."
    );

    // Ensure the user's Firestore document and Realtime Database table are created
    await checkAndCreateUserDoc(user);
    await checkAndCreateUserTable(user, seasonYear, nextYear);
  } catch (error) {
    console.error("Error during registration", error);
    alert(error.message);
  }
};

// Log in an existing user
const logInWithEmailAndPassword = async (
  email,
  password,
  seasonYear,
  nextYear
) => {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    const user = res.user;
    console.log("Logged in successfully!");

    // Ensure the user's Firestore document and Realtime Database table are created or updated
    await checkAndCreateUserDoc(user);
    await checkAndCreateUserTable(user, seasonYear, nextYear);
  } catch (error) {
    console.error("Error during login", error);
    alert("Invalid email or password");
  }
};

// Send password reset email
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (error) {
    console.error("Error sending password reset", error);
    alert(error.message);
  }
};
// Function to fetch user's favorite team from Firestore
const fetchFavoriteTeam = async (user) => {
  const userDocRef = doc(firestore, "users", user.uid);
  const userDocSnapshot = await getDoc(userDocRef);

  if (userDocSnapshot.exists()) {
    return userDocSnapshot.data().favoriteTeam;
  }
  return null;
};

// Function to save the favorite team and logo to Firestore
const saveFavoriteTeam = async (user, teamName, teamLogo) => {
  const userDocRef = doc(firestore, "users", user.uid);
  await updateDoc(userDocRef, {
    favoriteTeam: teamName,
    favoriteTeamLogo: teamLogo,
  });
};

// Function to fetch user's favorite team and logo from Firestore
const fetchTeamData = async (user) => {
  const userDocRef = doc(firestore, "users", user.uid);
  const userDocSnapshot = await getDoc(userDocRef);

  if (userDocSnapshot.exists()) {
    const data = userDocSnapshot.data();
    return {
      favoriteTeam: data.favoriteTeam,
      favoriteTeamLogo: data.favoriteTeamLogo,
    };
  }
  return { favoriteTeam: null, favoriteTeamLogo: null };
};

const fetchWeeklyScores = async () => {
  const weekliesRef = collection(firestore, "Weeklies");
  const snapshot = await getDocs(weekliesRef);

  return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

// Re-authenticate the user
export const reauthenticateUser = async (user, currentPassword) => {
  const cred = EmailAuthProvider.credential(user.email, currentPassword);
  await reauthenticateWithCredential(user, cred);
};

// Change user email
export const changeUserEmail = async (user, newEmail) => {
  await updateEmail(user, newEmail);
};

// Change user password
export const changeUserPassword = async (user, newPassword) => {
  await updatePassword(user, newPassword);
};

// Delete user account
export const deleteUserAccount = async (user) => {
  await deleteUser(user);
};

// Log out the user
const logout = () => {
  signOut(auth);
};

export {
  auth,
  db,
  firestore,
  getDatabase,
  ref,
  get,
  set,
  update,
  onValue, // Make sure these are exported
  registerWithEmailAndPassword,
  logInWithEmailAndPassword,
  sendPasswordReset,
  logout,
  checkAndCreateUserDoc,
  checkAndCreateUserTable,
  fetchFavoriteTeam,
  saveFavoriteTeam,
  fetchTeamData,
  fetchWeeklyScores,
};
