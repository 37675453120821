// src/components/ProtectedRoute.js
import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { YearContext } from "../contexts/YearContext";

const ProtectedRoute = () => {
  const { submitDate } = useContext(YearContext);
  const currentDate = new Date();

  // Redirect if the current date is after the submit date
  if (currentDate > submitDate) {
    return <Navigate to="/compare" />;
  }

  // Render the component if within the allowed time
  return <Outlet />;
};

export default ProtectedRoute;
