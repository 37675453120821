import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  auth,
  changeUserEmail,
  changeUserPassword,
  deleteUserAccount,
} from "../services/firebase";
import { reauthenticateUser } from "../services/firebase";

const Profile = () => {
  const [user] = useAuthState(auth);

  // State for updating email
  const [currentPasswordForEmail, setCurrentPasswordForEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");

  // State for updating password
  const [currentPasswordForPassword, setCurrentPasswordForPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  // State for status messages (success or error)
  const [statusMessage, setStatusMessage] = useState("");

  // Handle Email Change
  const handleChangeEmail = async (e) => {
    e.preventDefault();
    try {
      // Step 1: Re-authenticate the user (required by Firebase for sensitive ops)
      await reauthenticateUser(user, currentPasswordForEmail);

      // Step 2: Update Email
      await changeUserEmail(user, newEmail);
      setStatusMessage("Email updated successfully!");
    } catch (error) {
      setStatusMessage(error.message);
    }
  };

  // Handle Password Change
  const handleChangePassword = async (e) => {
    e.preventDefault();
    try {
      // Step 1: Re-authenticate the user
      await reauthenticateUser(user, currentPasswordForPassword);

      // Step 2: Update Password
      await changeUserPassword(user, newPassword);
      setStatusMessage("Password updated successfully!");
    } catch (error) {
      setStatusMessage(error.message);
    }
  };

  // Handle Account Deletion
  const handleDeleteAccount = async () => {
    if (!window.confirm("Are you sure you want to delete your account? This action is irreversible.")) {
      return;
    }
    try {
      // Must re-auth before deletion if the user hasn't recently logged in
      const currentPassword = prompt("Please enter your current password to confirm:");
      if (!currentPassword) return;

      await reauthenticateUser(user, currentPassword);
      await deleteUserAccount(user);
      setStatusMessage("Account deleted successfully. Redirecting...");
      // Optionally, log out the user or navigate somewhere after deletion
    } catch (error) {
      setStatusMessage(error.message);
    }
  };

  // If no user is logged in, show a message
  if (!user) {
    return <div>Please log in to view your profile.</div>;
  }

  return (
    <div className="min-h-screen flex flex-col items-center bg-base-200">
      <h1 className="text-4xl font-bold mb-6 text-center">Account Options</h1>
      
      {/* User Info */}
      <div className="mb-6 text-center">
        <h2>
          {user.displayName || "N/A"}
        </h2>
        <p>
          <strong>Email:</strong> {user.email}
        </p>
      </div>

      {/* Collapse for Changing Email */}
      <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box w-96 mb-4">
        <input type="checkbox" />
        <div className="collapse-title text-xl font-medium">
          Change Email
        </div>
        <div className="collapse-content">
          <form onSubmit={handleChangeEmail} className="pt-4">
            <div className="form-control mb-2">
              <label className="label">
                <span className="label-text">Current Password:</span>
              </label>
              <input
                type="password"
                value={currentPasswordForEmail}
                onChange={(e) => setCurrentPasswordForEmail(e.target.value)}
                className="input input-bordered"
                required
              />
            </div>
            <div className="form-control mb-4">
              <label className="label">
                <span className="label-text">New Email:</span>
              </label>
              <input
                type="email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                className="input input-bordered"
                required
              />
            </div>
            <button type="submit" className="btn btn-primary w-full">
              Update Email
            </button>
          </form>
        </div>
      </div>

      {/* Collapse for Changing Password */}
      <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box w-96 mb-4">
        <input type="checkbox" />
        <div className="collapse-title text-xl font-medium">
          Change Password
        </div>
        <div className="collapse-content">
          <form onSubmit={handleChangePassword} className="pt-4">
            <div className="form-control mb-2">
              <label className="label">
                <span className="label-text">Current Password:</span>
              </label>
              <input
                type="password"
                value={currentPasswordForPassword}
                onChange={(e) => setCurrentPasswordForPassword(e.target.value)}
                className="input input-bordered"
                required
              />
            </div>
            <div className="form-control mb-4">
              <label className="label">
                <span className="label-text">New Password:</span>
              </label>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="input input-bordered"
                required
              />
            </div>
            <button type="submit" className="btn btn-primary w-full">
              Update Password
            </button>
          </form>
        </div>
      </div>

      {/* Collapse for Deleting Account */}
      <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box w-96 mb-6">
        <input type="checkbox" />
        <div className="collapse-title text-xl font-medium text-error">
          Delete Account
        </div>
        <div className="collapse-content">
          <p className="text-sm text-error-content mt-4 mb-4">
            Deleting your account is irreversible. Proceed with caution!
          </p>
          <button onClick={handleDeleteAccount} className="btn btn-error w-full">
            Delete Account
          </button>
        </div>
      </div>

      {/* Status Message */}
      {statusMessage && (
        <div className="alert alert-info shadow-lg w-96">
          <div>
            <span>{statusMessage}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
